@import "~nms-design-system-core/scss/variables/variables.scss";
@import "../../../../../style/_variables.scss";

.db-pie-chart {
  .chart {
    max-width: 250px;
    max-height: $dc-chart-min-height-lg;
    margin: auto auto;

    @media (screen and max-height: $dc-window-height-breakpoint) {
      min-height: $dc-chart-min-height-sm;
      max-height: $dc-chart-min-height-sm;
      max-width: $dc-chart-min-height-sm;
    }
  }

}
