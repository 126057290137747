.resizer {
  -webkit-user-drag: none;

  .resizer-control {
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='40' height='40' fill='none' version='1.1' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m21.215 38.261 16.99-16.99-2.8316-2.8316-16.99 16.99zm-16.94-2.9929 2.8316 2.8316 30.881-29.768-2.8316-2.8316z' fill='%23C0C0C0' stroke-width='2.0023'/%3E%3C/svg%3E%0A");
    background-position: bottom right;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    overflow: hidden;
    text-align: center;
    cursor: ns-resize;

    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    transition: scale 180ms, filter 180ms;
    transform-origin: right bottom;

    &:hover {
      scale: 1.2;
    }
  }

  .resizer-background {
    position: fixed;
    display: none;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-user-drag: none;
  }
  &.active {
    .resizer-control {
      filter: brightness(1.2);
      z-index: 2;
    }
    .resizer-background {
      display: block;
    }
  }
}