body > .tooltip {
  // Avoid Bootstrap tooltip from blinking
  pointer-events: none;
  max-width: 280px;

  &.show { opacity: 1; }

  &.tooltip-sidebar > .tooltip-inner { max-width: 250px; }

  > .arrow::before { border-top-color: $primary-light; }
  &.bs-tooltip-bottom > .arrow::before { border-bottom-color: $primary-light; }
  &.bs-tooltip-top > .arrow::before { border-top-color: $primary-light; }
  &.bs-tooltip-left > .arrow::before { border-left-color: $primary-light; }
  &.bs-tooltip-right > .arrow::before { border-right-color: $primary-light; }

  > .tooltip-inner {
    max-width: 320px;
    background-color: white;
    color: #37474F;
    font-size: 12px;
    border: 2px solid $primary-light;
    box-shadow: $shadow-light;
  }

  // Tooltip created by tile
  .filter-count-table {
    display: table;

    > div {
      display: table-row;

      > span {
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: table-cell;

        &:not(:last-child) { padding-right: 8px; }
      }
    }
  }
}
