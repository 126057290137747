// CONTROLS
//
// General
//
.checkboxes,
.radios {
  label {
    margin-bottom: -5px;
    margin-right: 10px;
    display: inline-block;
    user-select: none;
    position: relative;
    border: solid 2px transparent;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    background: #FFFFFF;
    transition-duration: 0.25s;

    &::after {
      content: '';
      display: block;
      border: solid rgba(0, 0, 0, 0.1);
    }

    &:hover,
    &:focus { border: solid 2px $primary; }
    &:active {
      transition-duration: 0ms;
      -webkit-filter: brightness(0.8);
    }
  }

  input:checked {
    + label {
      color: $primary;
      animation: boom 0.5s ease;
    }
    + .blue-background {
      background: $primary;

      &::after {
        content: '';
        display: block;
        border: solid white;
      }
    }
  }
}
@keyframes boom {
  25% { transform: scale(1.25); }
}


// Checkbox
//
.checkboxes {
  label {
    width: 18px;
    height: 18px;
    border-radius: 2px;

    &::after {
      margin-left: 5px;
      margin-top: 0;
      width: 5px;
      height: 12px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
    }
  }

  input:checked + .blue-background::after { border-width: 0 2px 2px 0; }

  input:not(:checked) + .blue-background.semi-checked {
    background: $primary;

    &::after {
      margin-left: 1px;
      margin-top: 7px;
      width: 12px;
      height: 2px;
      border: solid 1px #FFFFFF;
      transform: rotate(0);
    }
  }
}
// Checkboxes in filter sidebar
@media screen and (min-width: 1201px) {
  .checkboxes .site-menu-item {
    label {
      width: 12px;
      height: 12px;
      margin-right: 7px;
      margin-bottom: -2.25px;

      &::after {
        width: 4px;
        height: 8px;
        margin-top: -1px;
        margin-left: 2.25px;
      }
    }

    input:not(:checked) + .blue-background.semi-checked::after {
      margin-top: 3px;
      width: 6px;
    }
  }
}


// Radio
//
.radios {
  label {
    width: 20px;
    height: 20px;
    border-radius: 10px;

    &::after {
      margin-left: 5px;
      margin-top: 5px;
      width: 5px;
      height: 5px;
      border-width: 3px 3px 3px 3px;
      border-radius: 4px;
    }
  }

  input:checked + .blue-background::after {
    margin-left: 5px;
    margin-top: 5px;
    width: 3px;
    height: 3px;
    border-width: 3px;
    border-radius: 3px;
  }
}


// Controls via Bootstrap 4.6x
//
input[type=radio] + label,
input[type=radio] + span,
input[type=checkbox] + label,
input[type=checkbox] + span {
  margin: 0 0 0 0.5rem;
}
.custom-control-label::before,
.custom-control-label::after {
  top: 0.125rem;
  left: -1.75rem;
}