.card {
  .card-header {
    .card-subtitle { font-size: 0.75rem; }
    .icon::before { font-size: 34px; }
    .card-title {
      display: flex;
      align-items: flex-start;


      .ico-anchor {
        opacity: 0.1;
        margin-top: -10px;
        margin-bottom: -10px;
        transition: opacity 150ms;
      }
    }
    &:hover .card-title .ico-anchor {
      opacity: 1;
    }
  }

  .card-body .table {
    background-color: $white;

    thead tr:first-child {
      th:first-child { border-top-left-radius: 0; }
      th:last-child { border-top-right-radius: 0; }
    }
  }
}

.html2canvas .card .card-header .card-subtitle {
  display: block;
}
