// FILTER ITEM GROUP
//
// Indicates an item in the filter list that has additional nested filters below it.
// Container for other filter items (it can not be filter itself).
//
// Imported into Filter.scss

.FilterItem.FilterItem-Group {
  > .item {
    border-radius: 0.25rem;
    > label {
      font-weight: bold;
    }
  }
  > .subtree {
    padding-left: 0.5rem;
  }
}
