@import "~nms-design-system-core/scss/variables/variables.scss";
.CopyToClipboard {
  > .icon.ico-content-copy {
    height: 16px;
    width: 16px;
    display: inline-flex;
    transition: color 200ms;
    color: #0063AE;
    vertical-align: middle;
    cursor: pointer;

    &:before {
      font-size: 25px;
    }
    &:hover {
      color: #62A8EA;
    }
  }
}