.FilterItem.FilterItem-Search {
  display: flex;
  position: relative;
  padding: 0.25rem 0.5rem;
  transition: height 250ms, box-shadow 250ms;

  > .ico-search {
    position: absolute;
    top: 21px;
    left: 13px;
  }

  > .reset-filter-search {
    position: absolute;
    top: 21px;
    right: 13px;
  }

  input {
    padding-left: 36px;
    border: 1px solid rgba(0,0,0, 0.1);
    border-radius: $border-radius;
    height: 48px;
    flex: 1 1 auto;
  }

  &.stick-to-top {
    position: fixed;
    top: 64px;
    width: 304px;

    @media (max-width: 991.98px) {
      width: 100%;
    }


    z-index: 2;
    background: $white;
    box-shadow: $shadow-dark;

    > .ico-search,
    > .reset-filter-search { top: 15px; }

    input { height: 36px; }
  }
}