.FilterItem.FilterItem-Group > .subtree {
  > .FilterItem.FilterItem-TilesVariant {
    > .item > .control {
      align-items: center;

      > .icon { margin: 0; }

      > .tiles {
        margin: 0 0.125rem 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}
