@import "~nms-design-system-core/scss/variables/variables.scss";
@import "../../../../../style/_variables.scss";

.db-scatter-chart {
  .chart {
    min-height: $dc-chart-min-height-lg;
    @media (screen and max-height: $dc-window-height-breakpoint) {
      min-height: $dc-chart-min-height-sm;
    }
  }
}
