@import "~nms-design-system-core/scss/variables/variables.scss";
@import "~nms-design-system-core/scss/variables/colors";

.db-compare-table {
  .table {
    .form-control {
      margin: 0;
      font-size: 0.75rem;
    }

    thead {
      tr:first-child th label { display: none; }
      tr:not(:first-child) th {
        text-align: center;
        font-size: 0.75rem;

        &[data-field*="previousBlockDiff"] { background-color: $primary; }

        label { color: $text-light; }
      }
    }

    tbody {
      tr:nth-child(odd) td.freezeColumn {
        background-color: #FAFAFA;
      }
      tr:nth-child(even) td.freezeColumn {
        background-color: white;
      }
      tr:hover td.freezeColumn {
        background-color: #F2F6FA;;
      }

      td {
        &.freezeColumn {
          position: sticky;
          left: -1px;
          border: 1px solid #F3F3F3;
          z-index: 1;
          box-shadow: inset -1px 0 0px 0px rgb(0 0 0 / 0.2);
        }
        &.lowBase { color: $danger; }
        &.icon {
          display: table-cell;
          height: auto;
          width: auto;
          text-align: center;
          padding-top: 0;
          padding-bottom: 0;
          &::before {
            line-height: 18px;
          }
        }
        &[data-table-column*="previousBlockDiff"] {
          background-color: scale-color($primary, $lightness: +92.5%)
        }
        &[data-table-column*="score"],
        &[data-table-column*="count"],
        &[data-table-column*="diff"]:not(.icon) { text-align: right; }
      }
      th { line-height: 1; }
    }
    th {
      color: $text-light;
      background: $secondary;
    }
  }

  form th { text-align: left; }

  select {
    width: 100%;
    padding: 1px 2px;
    overflow-y: auto;

    &[multiple] { background-image: none; }

    option { padding: 4px 1px; }
  }

  .dashboard-component-loader:before {
    top: 170px;
  }


  @media print {
    select {
      width: 100%;
      margin: 0;
      padding: 1px 2px;

      option:not(:checked) { display: none; }
    }
  }

}