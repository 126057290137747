@import "~nms-design-system-core/scss/variables/variables.scss";
.dummy-content {
  opacity: 1;
  .dummy-dashboard-component {
    opacity: 0;

    &.animate {
      animation-name: slide-bottom;
      animation-duration: 480ms;
      animation-fill-mode: forwards;
    }
  }

  .dummy-h {
    width: 180px;
    height: 30px;
    background-color: #D9D9D9;
  }

  .dummy-line {
    width: 120px;
    height: 18px;
    background-color: #E9E9E9;
  }

  .dummy-icon {
    width: 30px;
    height: 30px;
    background-color: #D9D9D9;
    border-radius: 15px;
  }

  .dummy-chart {
    height: 300px;
    background-image: url("./dummyChart.svg");
    background-repeat: no-repeat;
    background-size: auto 300px;
    background-position: center;
  }
}
