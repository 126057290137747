.FilterItem.FilterItem-Group > .subtree {
  > .FilterItem.FilterItem-TilesMatrix {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:focus > .item,
    &:hover > .item { background: rgba(144, 144, 144, 0); }

    > .item {
      width: 28px;
      height: 28px;
      margin: 0.125rem;
      padding: 0.125rem;
      border: $b-dark;
      border-radius: $border-radius-sm;

      > .item-label {
        align-items: center;
        flex-direction: column;

        > label,
        > .count {
          width: 100%;
          margin: 0;
          padding: 0;
          overflow: hidden;
          font-style: normal;
          text-overflow: ellipsis;
          text-align: center;
        }
        > label {
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.4px;
        }
        > .count {
          font-weight: 500;
          font-size: 0.625rem;
          line-height: 18px;
          letter-spacing: 1.5px;
        }
      }

      &.checked {
        border: $b-primary;
        background: $tertiary-lightest;
        color: $primary;

        > .item-label > label { font-weight: 600; }
      }
      &.hover,
      &:hover,
      &:focus {
        border: $b-primary;

        > .item-label > label { font-weight: 600; }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &.FilterItem-TilesMatrix-lg > .item {
      width: 48px;
      height: 48px;
      margin: 0.25rem;
      padding: 0.25rem;
    }
  }
}
