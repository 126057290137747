// Datagrid
//

// General
//
.table tr th,
.table tr td {
  &:first-child {
    padding-left: 1rem;

    &.col-checkbox { padding: 0; }
    &.col-action { padding-left: 0.625rem; }
  }
  &:last-child {
    padding-right: 1rem;

    &.col-checkbox { padding: 0; }
    &.col-action { padding-right: 0.625rem; }
  }

  &.col-respondentServedAt,
  &.col-ratingFinishedAt { min-width: 120px !important; }
}

.datagrid {
  .input-droup-append { display: none; }    // Calendar icon for basic datepicker - hidden and replaced by flatpickr
}
.table thead.thead-light th .icon {
  min-width: 30px;

  &.ico-inform-outline { opacity: 0.6; }
}

// Thead
//
.datagrid table thead tr th .datagrid-column-header-additions { min-width: unset; } // TODO: fix at NMS design system core!
.datagrid thead th {
  .datagrid-col-filter-date-range {
    height: 100%;
    position: relative;

    &::before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27 28H13V17H27V28ZM24 10V12H16V10H14V12H13C11.89 12 11 12.89 11 14V28C11 28.5304 11.2107 29.0391 11.5858 29.4142C11.9609 29.7893 12.4696 30 13 30H27C27.5304 30 28.0391 29.7893 28.4142 29.4142C28.7893 29.0391 29 28.5304 29 28V14C29 12.89 28.1 12 27 12H26V10' fill='%23909090'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: 1;
    }

    .input-group { width: auto; }
    .flatpickr-wrapper {
      display: flex;
      width: 100%;
    }
    input.flatpickr {
      width: 100%;
      height: 15px;
      cursor: pointer;
      padding-bottom: 0;
      padding-top: 0;
      line-height: 12px;

      &:hover,
      &:focus,
      &:focus-visible {
        border-color: #CCCCCC;
        outline: 0;
      }

      &:nth-child(1) {
        border-bottom: 0;
        border-radius: 3px 3px 0 0;
      }

      &:nth-child(2) {
        border-top: 0;
        border-radius: 0 0 3px 3px;
      }
    }

    .form-control:disabled,
    .form-control[readonly] { background-color: $white; }
  }

  input,
  select { font-size: 0.75rem; }
  select {
    min-width: 100%;
    width: auto;
    margin: 0;
  }
  .bootstrap-select > select {
    left:0;
  }

  .dropdown-header {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: $text-gray;
  }
  .dropdown-divider { margin: 0.5rem 0 0 0; }
}

.table.table-sm thead tr.row-filters th { height: 40px; }

// Tfoot
//
.datagrid .row-grid-bottom .col-per-page .form-control { margin-bottom: 0; }    // Rows per page select
.datagrid .row-grid-bottom .col-per-page .btn.reset-filter {
  height: 31px;
  margin-top: -2px;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
}

// Buttons
//
.datagrid .btn-sm,
.datagrid .btn-group-sm > .btn {
  padding: 0;

  .icon {
    width: 30px;
    height: 30px;

    &::before { font-size: 30px; }
  }
}
.datagrid .btn-sm {
  min-width: 30px;
  height: 30px;
}
.datagrid .col-action-inline-edit {
  .btn-group,
  .btn-group-sm {
    display: flex;

    .btn {
      padding: 0 0.5rem;

      &:not(:last-child) { margin-right: 0.25rem; }
    }
  }
}
.datagrid tfoot .btn.reset-filter { display: inline-block; }


// Loader
.datagrid .dashboard-component-loader:before {
  top: 170px;
}

// Media Print
//
@media print {
  table,
  .table,
  .datagrid {
    thead th,
    tbody th {
      background: $gray-500 !important;
      color: white;
    }

    .icon { display: none; }

    .row-grid-bottom {
      .col-pagination,
      .col-per-page { display: none; }
    }
  }

  .col-respondentServedAt,
  .col-ratingFinishedAt { width: 135px !important; }

  .table-bordered > tbody > tr > td,
  .table-bordered > thead > tr > th { padding: 7px 5px; }

  // Stránka Dotazníky
  #snippet-ratingRequestList- .datagrid table thead tr:nth-child(n+3) { display: none; }
}