// MODAL
//
// General
//
.modal {
  .modal-dialog { box-shadow: $shadow-dialog; }

  .modal-header {
    display: flex;
    align-items: center;

    h2,
    h3,
    h4 { margin-bottom: 0; }

    .modal-controls {
      display: flex;
      align-items: center;

      * {
        margin-right: 0.5rem;

        &:last-child { margin-right: 0; }
      }
    }
  }

  .modal-body { min-height: 200px; }

  @media screen and (max-width: 515px){
    .modal-open .datagrid { display: none !important; }
  }
}

.modal-open .body-wrap > .sidebar,
.modal-open .body-wrap > .content-wrap > .container-fluid .row { filter: blur(7px); }

@media print {
  .modal {
    padding: 0 !important; // rewrite inline style
    background: transparent;
    position: static;

    .modal-dialog {
      max-width: unset;
      margin: 0;
      background-color: transparent;

      .modal-content {
        border: none;

        .modal-header { display: none !important; }
        .modal-body .row { break-inside: avoid; }
      }
    }
  }
  .modal-backdrop { background-color: transparent; }
  .modal-open > .navbar,
  .modal-open > .body-wrap { display: none; }
}


// User Permissions
//
.modal .modal-content.user-permissions {
  .modal-body { min-height: unset; }
  .custom-checkbox {
    margin: 0 0 0.5rem 0;

    &:last-child { margin: 0; }
  }
}