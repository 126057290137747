//
// Edit Bootstrap's .nav for NMS CID
//

// General
//
.nav {
  width: 100%;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  border: unset;
  background: $white;
}
@include media-breakpoint-up(md) {
  .nav { overflow: unset; }
}

// Content wrap
.body-wrap > .content-wrap > .nav {
  min-height: 46px;
  padding: 0.25rem 0 0 0;
  background: $primary;
}
@include media-breakpoint-up(md) {
  .body-wrap > .content-wrap > .nav {
    padding: 0 $content-p;
    background: $white;
  }
}
@include media-breakpoint-up(lg) {
  .body-wrap > .content-wrap > .nav {
    position: fixed;
    z-index: 10;
  }
}


// Nav link
//
.nav.nav-tabs .nav-link {
  min-width: 60px;
  margin-bottom: 0; // reset default Bootstrap style
  padding: 0.5rem 0.7rem;
  padding-bottom: calc(0.5rem - 4px);
  border: unset;
  border-bottom: solid 4px transparent;
  border-radius: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  //text-transform: uppercase;
  letter-spacing: 0.047rem;
  color: $text-dark;

  &.disabled { opacity: 0.5; }

  &:hover,
  &:focus {
    background: rgba(0,0,0,0.01);
    border-bottom-color: rgba(0, 0, 0, 0.1); // $b-transparent
  }

  &.active,
  &.show .nav-link { border-bottom-color: $primary; }
}
@include media-breakpoint-up(md) {
  .nav.nav-tabs .nav-link {
    padding: 0.75rem 1rem;
    padding-bottom: calc(0.75rem - 4px);
  }
}

// Content wrap
@include media-breakpoint-down(sm) {
  .body-wrap > .content-wrap > .nav.nav-tabs .nav-link {
    color: $text-light;

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
      border-bottom-color: rgba(0,0,0,0.25);
    }

    &.active,
    &.show .nav-link {
      background: $primary;
      border-bottom-color: $primary-lightest;
    }
  }
}


// Nav for cards
// used like a content switch for .card
//
.card > .card-header > .nav {
  width: calc(100% + 2rem);
  margin: 0.5rem $card-p-n $card-p-n $card-p-n; // negative values "$card-p-n" because .card-header padding
}


// Dropdown
//
.body-wrap > .content-wrap > .nav .dropdown,
.card > .card-header > .nav .dropdown {
  .nav-link.dropdown-toggle {
    &::after {
      margin-left: 0.5rem;
      transition: transform 500ms;
    }
  }
  &.show .nav-link.dropdown-toggle::after {
    transform: rotate(-180deg);
  }

  > .dropdown-menu,
  .submenu > .dropdown-menu {
    border: $b-transparent;
    box-shadow: $shadow-dropdown;
  }
  .submenu > .dropdown-menu { border-radius: 8px; }
}

// Content wrap Dropdown
@include media-breakpoint-down(sm) {
  .body-wrap > .content-wrap > .nav .dropdown,
  .card > .card-header > .nav .dropdown {
    > .nav-link.dropdown-toggle { display: none; }

    > .dropdown-menu {
      margin: 0;
      padding: 0;
      display: flex;
      position: relative;
      top: 0;
      box-shadow: none;
      background: $primary;
      border: transparent;
      border-radius: 0.25rem 0.25rem 0 0;

      .dropdown-item {
        padding: 0.705rem;
        font-style: normal;
        font-weight: 500;
        font-size: 0.85rem;
        line-height: 1rem;
        letter-spacing: 0.047rem;
        text-transform: uppercase;
        color: $text-light;

        &:hover,
        &:focus {
          background: rgba(0,0,0,0.25);
          border-radius: 0.25rem 0.25rem 0 0;
        }

        &:active,
        &.active {
          padding-bottom: 0.55rem;
          background-color: $primary;
          border-radius: 0;
          color: $text-light;

          &:hover,
          &:focus { color: $text-dark; }
        }
      }
    }
  }
}

// .card nav Dropdown
@include media-breakpoint-down(sm) {
  .card > .card-header > .nav .dropdown {
    > .dropdown-menu {
      background: $white;
      border-radius: 0;

      .dropdown-item {
        color: $text-dark;
        border-bottom: $b-transparent;
        border-bottom-color: transparent;
        border-bottom-width: 4px;

        &:hover,
        &:focus {
          background: $white;
          border-radius: 0;
          border-bottom-color: rgba(0, 0, 0, 0.1); // $b-transparent
        }

        &:active,
        &.active {
          background: $white;
          border-bottom-color: $primary;
          color: $text-dark;
        }

        &.disabled { opacity: 0.5; }
      }
    }
  }
}


// Multilevel
//
.body-wrap > .content-wrap > .nav {
  .dropdown-menu li {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    > .dropdown-item {
      width: auto;
      flex: 1;
    }

    &.dropdown-toggle {
      //width: calc(100% - 40px);

      &::after { display: none; }   // Default toggle for .navs dopdowns was replaced by dropdown icon

      > .dropdown-item { padding-right: 0; }

      >  .submenu > .icon {
        height: 30px;
        color: $text-dark;

        &.ico-dropdown {
          cursor: pointer;
          transform: rotate(0);
          transition: transform 500ms;

          &:hover,
          &:focus { transform: rotate(-180deg); }
        }
      }
    }

    > .dropdown-menu,
    .submenu > .dropdown-menu {
      border: $b-transparent;
      box-shadow: $shadow-dropdown;
    }
  }

  .dropdown .submenu > .dropdown-menu { display: none; }
  .dropdown .dropdown-toggle > .submenu:hover > .dropdown-menu,
  .dropdown .dropdown-toggle > .submenu:focus > .dropdown-menu { display: block; }
}
@include media-breakpoint-up(sm) {
  .body-wrap > .content-wrap > .nav {
    .dropdown-menu li {
      position: relative;

      &.dropdown-toggle > .submenu {
        > .icon.ico-dropdown { transform: rotate(-90deg); }
        &:hover > .icon.ico-dropdown,
        &:focus > .icon.ico-dropdown { transform: rotate(90deg); }
      }
    }

    .dropdown .submenu > .dropdown-menu {
      position: absolute;
      top: -3px;
      left: 100%;
    }
  }
}