@import "~nms-design-system-core/scss/variables/variables.scss";
// VARIABLES
@import "~nms-design-system-core/scss/variables/variables";
@import "variables";

// LIBS
@import "~bootstrap";
@import "~bootstrap-table/src/bootstrap-table.scss";
@import "../lib/animsition/animsition.css";
@import "../lib/asscrollable/asScrollable.css";
@import "../lib/switchery/switchery.css";
@import "../lib/intro-js/introjs.css";
@import "../lib/slidepanel/slidePanel.css";
@import "../lib/chartist/chartist.min.css";
@import "../lib/chartist-plugin-tooltip/chartist-plugin-tooltip.min.css";
@import "../lib/izitoast/iziToast.min.css"; // 0.1 MB
@import "../lib/ublaboo/datagrid";
@import "../lib/ublaboo/datagrid-spinners";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~select2";
@import "~flatpickr";
@import "~nouislider";
@import "~ol/ol.css";

// Import parts of nms design
@import "~nms-design-system-core/scss/layout/layout";
@import "~nms-design-system-core/scss/components/alert";
@import "~nms-design-system-core/scss/components/buttons";
@import "~nms-design-system-core/scss/components/badge";
@import "~nms-design-system-core/scss/components/card";
@import "~nms-design-system-core/scss/components/dropdowns";
@import "~nms-design-system-core/scss/components/form";
@import "~nms-design-system-core/scss/components/input";
@import "~nms-design-system-core/scss/components/navbar";
//@import "~nms-design-system-core/scss/components/navs";
@import "~nms-design-system-core/scss/components/modal/modal";
@import "~nms-design-system-core/scss/components/modal/modal-calendar";
@import "~nms-design-system-core/scss/content/tables";
@import "~nms-design-system-core/scss/content/typography";
@import "~nms-design-system-core/scss/datagrid/datagrid";
@import "components/UserProfile";

@import "~nms-design-system-core/scss/extend/icons/icon-font";

@import "~nms-design-system-icon/dist/main";

// ICONS
@import "fonts/web-icons/web-icons.min.css"; // 0.15 MB

// OWN STYLES
@import "general";
@import "buttons";
@import "card";
@import "form";
@import "gap";
@import "loader";
@import "animation";
@import "controls";
@import "layout";
@import "print";
@import "navbar";
@import "navs";
@import "highlight";
@import "highcharts";
@import "prefers";

// COMPONENTS
@import "components/boostrap_select";
@import "components/ClientGrid";
@import "components/componentFilters";
@import "components/datagrid";
@import "components/dropdown";
@import "components/error";
@import "components/Flatpickr";
@import "components/ImportGrid";
@import "components/Map";
@import "components/modal";
@import "components/PrintTitle";
@import "components/RatingRequestDetail";
@import "components/resizer";
@import "components/ResponseRateTable";
@import "components/tooltip";
@import "components/TranslatableElement";
@import "components/UploadedFileSetting";

// CHARTS
@import "charts/interval-control";
@import "charts/nps";
@import "charts/stars";
@import "charts/var-value";
@import "charts/fullscreen-controls";

// PAGES
@import "page/login";
@import "page/login-legacy";
@import "page/projects";
