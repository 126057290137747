.UploadedFileSetting {
  padding: 0 0 1rem 0;
  display: flex;
  flex-wrap: wrap;
  border-top: $b-dark;
  font-size: 0.875rem;

  input[type="radio"] {
    min-width: 1rem;
    width: 1rem;
    margin-right: 0.75rem;

    + span {
      margin: 0;
      font-size: 0.875rem;
    }
  }

  label {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: $text-dark;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $text-link;

      span { color: $text-link; }
    }
  }

  .form-control { margin-bottom: 0.5rem; }

  .select2 {
    margin: 0 0 0.5rem 0;
    border: none;
  }
}