// fix bootstrap-select dropdown
.icon.ico-check {
  @extend .ico-check-simple;
}

.icon.ico-check::before {
  font-size: 20px;
  color: $success;
}

.bootstrap-select {
  li:not(.active):not(:active) a.dropdown-item {
    color: $text-dark;
  }
}

.datagrid table thead tr .bootstrap-select:not([class*=col-]):not(.input-group-btn) {
  width: 100%;
  border: $b-dark;

  > .btn {
    width: 100%;
    padding: 0.25rem 0.5rem;
    background: transparent;
    font-weight: 400;
    color: $text-dark;
  }
}