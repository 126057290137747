.FilterItem.FilterItem-TilesRow {
  > .item { cursor: default; }

  > .item > .control {
    align-items: center;

    > .icon { margin: 0; }

    > .tiles {
      margin: 0 0.125rem 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      > .option {
        width: 40px;
        height: 30px;
        padding: 0.125rem;
        border: $b-dark;
        border-radius: $border-radius-sm;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        //&:first-child { border-radius: $border-radius-sm 0 0 $border-radius-sm; }
        //&:last-child { border-radius: 0 $border-radius-sm $border-radius-sm 0; }
        &:not(:last-child) { margin-right: 3px; }

        &.has-tooltip::before {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          top: 0;
          right: 0;
          border-top: solid 4px $accent;
          border-right: solid 4px $accent;
          border-bottom: solid 4px transparent;
          border-left: solid 4px transparent;
        }

        &.checked {
          border: $b-primary;
          z-index: 1;
          opacity: 1;

          > label { color: $primary; }
        }
        &.hover,
        &:hover,
        &:focus { z-index: 2; }

        &.hover,
        &:hover {
          border: $b-primary;
          opacity: 1;

          > label { color: $text-dark; }
        }

        &.disabled { pointer-events: none; }

        > label,
        > .count {
          width: 100%;
          margin: 0;
          padding: 0;
          display: block;
          overflow: hidden;
          font-style: normal;
          text-overflow: clip;
          white-space: nowrap;
          text-align: center;
          cursor: pointer;
        }
        > label {
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 2;
          letter-spacing: 0.4px;
        }
        > .count {
          font-weight: 500;
          font-size: 0.625rem;
          line-height: 18px;
          letter-spacing: 1.5px;
          color: $text-gray;
        }
      }
    }
  }

  &.FilterItem-TilesRow-lg > .item > .control > .tiles > .option {
    width: 48px;
    height: 48px;
    padding: 0.25rem;
  }
}

