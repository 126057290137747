@import "~nms-design-system-core/scss/variables/variables.scss";
.card-body.table-responsive {
  container: card-body / inline-size;

  .datagrid tfoot .row-grid-bottom {
    overflow: hidden;
    white-space: nowrap;

    > * {
      position: relative;
    }

    .col-items {
      width: calc(25cqw - 0.67rem - 2px);
    }

    .col-pagination {
      width: calc(50cqw - 0.67rem);
    }

    .col-per-page {
      width: calc(25cqw - 0.67rem - 2px);
    }
  }
}
