@import "~nms-design-system-core/scss/variables/variables.scss";
@import "../../../../../style/variables";
.db-rank-table {
  table tbody {
    tr td {
      position: relative;
    }
    tr.success,
    tr.danger
    {
      td:first-child:before {
        content: ' ';
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        width: 3px;
      }
    }

    tr.lowBase {
      // Opacity, background, color, ...?
      color: $danger;
    }

    tr.success td:first-child:before {
      background-color: $success;
    }
    tr.danger td:first-child:before {
      background-color: $danger;
    }
    tr td:nth-child(n+2) {
      text-align: right;
    }
  }
}
