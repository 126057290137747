@import "~nms-design-system-core/scss/variables/variables.scss";
// Encode Open Question Frequency Table
//
.db-encoded-open-question-frequency-table {
  font-size: 0.875rem;

  [data-field="score"] {
    width: 0;
  }

  thead tr th {
    background-color: $gray-500 !important;
    color: white;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        height: 20px;
        color: white;
      }
    }
  }


  &.sentiment-positive thead tr th:first-child { background: $success !important; }
  &.sentiment-negative thead tr th:first-child { background: $danger !important; }

  .tableFixHead {
    overflow: auto;
    max-height: 350px;

    th {
      position: sticky;
      top: -1px;
    }
  }
}

@media print {
  .print-tables-visible-all-rows {
    .db-encoded-open-question-frequency-table {
      .tableFixHead {
        max-height: unset;

        &::-webkit-scrollbar { display: none; }
        table thead { page-break-after: avoid; }
        table tr { page-break-inside: avoid; }
      }
    }
  }
}
