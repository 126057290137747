@import "~nms-design-system-core/scss/components/sidebar/user-profile";
@import "../variables";

.user-badge {
  border-radius: 20px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  @media (min-width: 768px) {
    background: $primary-darkest;
  }

  &.user-badge-anotherUser { background: $accent; }
  > span:not(.icon):first-child { padding-left: 1rem; }
}

.navbar-dark .user-badge {
  background-color: $text-gray;

  &.user-badge-anotherUser {
    background: $accent;
  }

  &.nav-link {
    color: white;
  }
}