// FORM
//
// General
//
form {
  th,
  td {
    vertical-align: top;
    font-weight: normal;
  }
  th { text-align: right; }

  .error {
    color: $danger;
    font-weight: bold;
  }
  .required label { font-weight: bold; }
}


// Form inline
//
@media (min-width: 768px) {
  .form-inline .radio-custom,
  .form-inline .checkbox-custom {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;

    label {
      padding-left: 10px;
    }

    input[type="checkbox"],
    input[type="radio"] {
      position: absolute;
      margin-left: -20px;
    }
  }
}


// Form horizontal
//
.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  min-height: 2.073rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: .501rem;
}


// Form material    // TODO: delete after login page update
//
.form-material {
  position: relative;

  textarea.form-control,
  select[multiple],
  select[size] { height: auto; }

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &.floating {
    margin-top: 20px;
    margin-bottom: 20px;

    + .form-material.floating { margin-top: 40px; }

    textarea.form-control { padding-top: .429rem; }
  }

  .floating-label {
    font-size: 1rem;
    color: $text-gray;
    position: absolute;
    pointer-events: none;
    left: 0;
    transition: .3s ease all;

    &.floating-label-static {
      position: relative;
      top: auto;
      display: block;
    }
  }

  .form-control {
    padding-left: 0;
    padding-right: 0;
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    transition: background 0s ease-out;
    float: none;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    background-image: linear-gradient($text-link, $text-link), linear-gradient($bg-light, $bg-light);

    ~ .floating-label {
      font-size: 1rem;
      top: .571429rem;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder { color: $text-gray; }
    &:-webkit-autofill ~ .floating-label {
      top: -.8rem;
      font-size: .8rem;
    }

    &:disabled::-webkit-input-placeholder,
    &:disabled::-moz-placeholder,
    &:disabled:-ms-input-placeholder { color: #ccd5db; }

    &:focus,
    &.focus {
      outline: none;
      background-size: 100% 2px, 100% 1px;
      transition-duration: .3s;
      float: none;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      background-image: linear-gradient($text-link, $text-link), linear-gradient($bg-light, $bg-light);

      ~ .floating-label {
        top: -.8rem;
        font-size: .8rem;
      }
    }

    &:not(.empty) ~ .floating-label {
      top: -.8rem;
      font-size: .8rem;
    }

    &:not(.empty):invalid ~ .floating-label,
    &.focus:invalid ~ .floating-label { color: #f96868; }
    &:invalid { background-image: linear-gradient(#f96868, #f96868), linear-gradient($bg-light, $bg-light); }
  }

  .form-control-label { font-weight: 700; }
  &.form-group .form-control-label { padding: 0; }

  [class*="col-"] > .floating-label { left: .9375rem; }

  .form-control-sm {
    ~ .floating-label {
      font-size: .858rem;
      top: .429rem;
    }

    &:focus,
    &.focus,
    &:not(.empty) {
      ~ .floating-label {
        top: -.6864rem;
        font-size: .6864rem;
      }
    }

    &:-webkit-autofill ~ .floating-label {
      top: -.6864rem;
      font-size: .6864rem;
    }
  }
  .form-control-lg {
    ~ .floating-label {
      font-size: 1.286rem;
      top: .428667rem;
    }

    &:focus,
    &.focus,
    &:not(.empty),
    &:-webkit-autofill {
      ~ .floating-label {
        top: -1.0288rem;
        font-size: 1.0288rem;
        font-weight: 700;
      }
    }
    &:focus ~ .floating-label,
    &.focus ~ .floating-label { color: $text-link; }
  }

  textarea.form-control {
    resize: none;
    padding-bottom: .429rem;
  }
  select.form-control {
    border: 0;
    border-radius: 0;
  }

  &:not(.floating) .form-control-label + select[multiple] { margin-top: 5px; }

  .hint {
    position: absolute;
    font-size: 80%;
    display: none;
  }
  &:focus ~ .hint,
  &.focus ~ .hint { display: block; }

  .input-group {
    .form-control-wrap {
      margin-right: 5px;
      margin-left: 5px;
      position: relative;

      .form-control { float: none; }
    }

    .input-group-addon {
      border: 0;
      background: transparent;
    }

    .input-group-btn .btn {
      border-radius: .286rem;
      margin: 0;
    }
  }
}

.form-material .form-control:disabled,
.form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control {
  background: transparent;
  border-bottom: 1px dashed $gray-100;

  ~ .floating-label { color: $gray-100; }
}

.form-material.form-group.has-warning {
  .form-control {
    &:focus,
    &.focus,
    &:not(.empty),
    &:-webkit-autofill {
      background-image: linear-gradient($accent, $accent), linear-gradient($bg-light, $bg-light);

      ~ .floating-label { color: $accent; }
    }
    &:not(.empty) { background-size: 100% 2px, 100% 1px; }
  }
  .form-control-label { color: $accent; }
}
.form-material.form-group.has-danger {
  .form-control {
    &:focus,
    &.focus,
    &:not(.empty),
    &:-webkit-autofill {
      background-image: linear-gradient($danger, $danger), linear-gradient($bg-light, $bg-light);

      ~ .floating-label { color: $danger; }
    }
    &:not(.empty) { background-size: 100% 2px, 100% 1px; }
  }
  .form-control-label { color: $danger; }
}
.form-material.form-group.has-success {
  .form-control {
    &:focus,
    &.focus,
    &:not(.empty),
    &:-webkit-autofill {
      background-image: linear-gradient($success, $success), linear-gradient($bg-light, $bg-light);

      ~ .floating-label { color: $success; }
    }
    &:not(.empty) { background-size: 100% 2px, 100% 1px; }
  }
  .form-control-label { color: $success; }
}
.form-material.form-group.has-info {
  .form-control {
    &:focus,
    &.focus,
    &:not(.empty),
    &:-webkit-autofill {
      background-image: linear-gradient($info, $info), linear-gradient($bg-light, $bg-light);

      ~ .floating-label { color: $info; }
    }
    &:not(.empty) { background-size: 100% 2px, 100% 1px; }
  }
  .form-control-label { color: $info; }
}

.form-group {
  label > input[type="checkbox"]:first-child {
    margin-inline-end: 0.5rem;
  }
}

label.required::after {
  content: ' *';
  color: $danger;
}