@import "~nms-design-system-core/scss/variables/variables.scss";
// KB TABLE
//

.kb-table {
  thead th,
  thead td {
    &.render-as-numeric {
      min-width: 100px;
      max-width: 100px;
      word-break: break-all;
    }

    div {
      display: flex;
      align-items: center;

      > span { flex-grow: 1; }
    }
  }

  td.render-as-date .date { white-space: nowrap; }
  td.render-as-vimeo { padding: 0; }
  tbody td.render-as-numeric { text-align: center; }
}