/// RATING REQUEST DETAIL
//
// General
//
.mainheader-row {
  margin-bottom: 15px;
  padding: 8px 15px;
  background: $primary-darkest;
  font-weight: 500;
  color: $text-light;
}

.header-row {
  margin-bottom: 10px;
  padding: 8px 15px;
  background: $primary;
  font-weight: 500;
  color: $text-light;
}

.subheader-row {
  padding: 8px 15px;
  background: #E1DFE0;
  font-size: 0.75rem;
  font-weight: 500;
  color: #000000;
}

.content-row {
  margin-bottom: 10px;
  padding: 8px 15px;
  background: #FFFFFF;
  font-size: 0.75rem;

  a { word-break: break-all; }
}

@media print {
  .mainheader-row {
    margin-top: 3em;
  }
  .header-row {
    color: $text-light !important;
    background: $primary !important;
  }
  .subheader-row {
    background: $bg-light !important;
  }
}
