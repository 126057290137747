// response-rate-table
//

.db-response-rate-table {
  .tr-primary td {
    background: $secondary;
    color: $text-light;
    font-weight: bold;
  }

  .tr-secondary td{
    background: $secondary-light;
    color: $text-light;
  }
}