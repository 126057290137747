.TranslatableElement {
  position: relative;
  display: flex;
  flex-direction: column;

  // Hack for IE10 and IE11
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }

  &.citation {
    margin: -10px -15px -13px -15px; // cancels the padding of the cell, because we want the whole cell to respond to the hover cursor, not just the text inside the cell
    padding: 10px 12px; // add original cell's padding
    max-height: unset !important; // !important to overwrite .citation's max-height: 52px;
    display: flex !important; // !important to overwrite .citation's display: block;
  }

  &.translating {
    animation-name: pulsing-background-blue;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    .translation-source,
    .translation-target {
      background-color: transparent;
      border: none;
    }
  }

  .translation-translate {
    padding: 0.25rem;
    display: inline-block;
    font-size: 0.65rem;
    font-weight: 700;
    letter-spacing: .4px;
    text-transform: uppercase;
    color: $info;
    background-color: transparent;
    transition: background .1s ease-out;

    @media print {
      display: none;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: rgba(0,0,0,0.04);
    }

    .icon {
      width: 30px;
      height: 30px;
      padding: 12px;
      color: #009ddf;

      &::before {
        font-size: 30px;
      }
    }
  }

  .translation-source {
    margin: 0;
    padding: 4px 0 10px 0;
    border-bottom: dashed 1px $tertiary-light;
  }
  .translation-target {
    margin: 0 -12px -8px -12px;
    padding: 10px 12px;
    background: rgba(0, 0, 0, 0.02);
    font-style: italic;
  }
}

@keyframes pulsing-background-blue {
  0% { background: transparent; }
  50% { background: rgba($tertiary, 0.2); }
  100% { background: transparent; }
}