.PrintTitle {
  margin: 0 0 3rem 1rem;

  display: none;
  @media print {
    display: block;
  }

  p {
    opacity: 0.8; font-size: 1.5rem
  }
}
