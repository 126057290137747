@import "~nms-design-system-core/scss/variables/variables.scss";
.jezisek {
  pointer-events: none;

  .jezisek-moving {
    z-index: 99999;
    position: fixed;
    top: 10px;
    filter: drop-shadow(0 0 12px rgba(255,255, 199, 0.3));
    animation: jezisek linear 240s 1s 1 both;

  }

  .jezisek-body {
    position: absolute;
    left: 153px;
    top: 0;
  }

  .jezisek-bubble {
    position: absolute;
    left: 0;
    top: 50px;
  }

  .jezisek-message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    left: 12px;
    top: 84px;
    width: 104px;
    height: 63px;
    font-size: 87%;
    color: white;

    > div {
      text-align: center;
    }
  }

  .jezisek-particle {
    z-index: 99998;
    position: fixed;
    animation: jezisek-particle ease-in 6s 0s 1 both;
    // color, scale, animation-length is set from js
  }
}

@keyframes jezisek {
  0% { left: calc(100% - 0px); }
  100% { left: calc(100% - 9999px); }
}

@keyframes jezisek-particle {
  0% { translate: 0 0; opacity: 1; transform: rotate3d(1,1,1, 45deg);}

  80% { opacity: 1; }
  100% { translate: 0 30px; opacity: 0; transform: rotate3d(1,1,1, -270deg);}
}
