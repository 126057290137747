// Styles for main login screen
//
// General
//
body.page-login-v3 {
  display: flex;
  background: url("../images/bg-main.jpg") center center no-repeat $tertiary-light !important;
  background-size: cover;

  > .container-fluid {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > .container-fluid > .card {
    width: 95%;
    max-width: 400px;
    border: none;
    box-shadow: $shadow-dropdown;

    .card-header {
      margin: 0;
      padding: 2rem 2rem 0 2rem;
      flex-direction: column;
      border: none;
      font-family: "Ubuntu", Arial, sans-serif;
      text-align: center;

      h2 {
        font-size: 3rem;
        font-weight: 600;
        color: $primary;
      }

      p { color: $info; }

      img {
        max-width: 100%;
        height: 70px;
        margin: 0 10px 40px 10px;
      }
    }

    .card-body {
      form {
        .form-material.floating + .form-material.floating { margin-top: 0; }
        .form-group > a {
          margin: 1.5rem 0;
          display: flex;
          align-items: center;

          .icon { color: $primary; }

          &:hover .icon,
          &:focus .icon { color: #62A8EA; }
        }

        .form-material .form-control.focus ~ .floating-label,
        .form-material .form-control:focus ~ .floating-label { color: $info; }

        .form-material .form-control,
        .form-material .form-control.focus,
        .form-material .form-control:focus { background-image: linear-gradient($info, $info), linear-gradient($info, $info); }
      }
    }
  }

  > .container-fluid > .card + footer {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p { font-size: 0.75rem; }

    img {
      width: 100px;
      margin-bottom: 1rem;
    }
  }

  .btn-primary {
    margin: 1rem auto 0.5rem auto;
    border-radius: 3px;

    background: $primary;
    box-shadow: $shadow-primary;
    border: none;

    &:hover,
    &:focus {
      border-radius: 3px;
      background: $info;
      box-shadow: $shadow-primary;
      border: none;
    }

    &.active.focus,
    &.active:focus,
    &.active:hover,
    &:active.focus,
    &:active:focus,
    &:active:hover {
      border-color: $success;
      background-color: $success;
    }
  }
  .open > .btn-primary.dropdown-toggle.focus,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle:hover {
    border-color: $success;
    background-color: $success;
  }
}
