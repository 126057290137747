//
// Navbard
//

// General
//
nav.navbar {
  z-index: 1001;

  .navbar-nav > .nav-item > .nav-link {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.navbar.navbar-dark { background-color: $primary-darkest; }
.navbar-nav .dropdown-menu { position: absolute; }

// dropdown menu for mobil
.localeFlag:hover {
  text-decoration: none;
  filter: brightness(1.1);
}