@media (prefers-contrast: less) {
  .body-wrap > .content-wrap {
    background: white;
  }
  .navbar {
    background: $primary-lighter;
  }
  .card {
    box-shadow: none;
  }
}

@media (prefers-contrast: more) {
  .body-wrap > .content-wrap {
    background: $primary-lighter;
  }
  .navbar {
    background: $primary-darkest;
  }
  .card {
    border: 2px solid $primary-darker;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show {
    background: $primary-lighter;
    &:hover {
      background: $primary-light;
    }
  }

}