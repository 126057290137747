// FILTER MINI
//
// The default filters are adapted primarily for touch control.
// Therefore, it is possible to set the "mini" variant, which adjusts the dimensions of individual items.
// This allows more to be displayed in the filter panel at once without the user having to scroll.
//
// Works for desktops only. The default setting will be preferred on mobile devices.
//
@media screen and (min-width: 992px) {
  &.FilterMini .FilterItem {
    &.opened > .item { margin-bottom: 0.125rem; }

    > .item {
      padding: 0.125rem;

      > .icon { margin-right: 0.5rem; }

      > label {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    > .control > .icon {
      &:hover::after,
      &:focus::after {
        width: 80%;
        height: 80%;
      }
    }
  }

  &.FilterMini > .tree > .FilterItem {
    &:first-child { padding-top: 0.125rem; }
    &:last-child { padding-bottom: 0.125rem; }
    &.opened { padding-bottom: 0.125rem; }

    > .item { padding: 0.3125rem 0.25rem 0.3125rem 0.412rem; }

    > .subtree { padding: 0 0 0.5rem 0.188rem; }
  }

  &.FilterMini .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: -0.5rem 0;
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: #909090;

    &::before {
      font-size: 30px;
      line-height: 100%;
    }
  }
}