@import "~nms-design-system-core/scss/variables/variables.scss";
.db-alert-summary {
  .card-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.bg-danger {
    .label,
    a,
    .icon { color: $text-light; }
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}

@media print {
  .db-alert-summary {
    a {
      text-decoration: none;
    }
  }
}
