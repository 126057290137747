.FilterItem.FilterItem-Group > .subtree > .FilterItem.FilterItem-Period {
  margin-left: -0.5rem;
}

.FilterItem.FilterItem-Period {

  &.preselected-checked,
  &.preselected-semi-checked {
    > .item {
      border-left-color: $primary;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.preselected-semi-checked > .item {
    position: relative;
    border-left-style: dashed;
    &:after, &:before {
      content: '';
      position: absolute;
      left: -1px;
      width: 1px;
      height: 1px;
      background: white;
      z-index: 1;
    }
    &:after {
      bottom: 0;
    }
    &:before {
      top: 0;
    }
  }


  &.shortenedPeriod {
    > .item > .icon:first-child {
      color: $primary;
    }
  }

}
