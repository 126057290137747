.highlight {
  border-radius: 4px;
  border: 1px dashed #e5e5e5;
  padding: 0.5rem 0.5rem 0.5rem 56px;
  background-size: 22px 22px;
  background-position: 17px 50%;
  background-repeat: no-repeat;


  &.highlight-info {
    border-color: #89AFE2;
    background-color: rgba(137, 175, 226, 0.1);
    background-image: url("./images/icon-bulb.svg");
  }
}

.highlight-blink {
  animation: highlight 0.3s;
  animation-iteration-count: 5;
  animation-timing-function: ease-out;
}
