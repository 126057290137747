@import "~nms-design-system-core/scss/variables/variables.scss";
// RESPONSE RATE
//
.response-rate .card-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .label {
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0.4px;
  }
}
@media screen and (max-width: 470px){
  .response-rate .card-body .label { width: 100%; }
}

@media print {
  .response-rate-card .icon {
    display: flex !important;
  }
}
