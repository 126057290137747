// LOADER
//
body > .loader {
  margin: -20px auto 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  transform: translateZ(0);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 130px;
  left: 0;
  background: rgba(0,0,0,0.2);

  &.loader-with-side-menu {
    width: calc(100% - 304px);
    left: 304px;

    @media screen and (max-width: 992px) {
      width: 100%;
      left: 0;
    }
  }
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $primary-light;
  z-index: 999999;

  .loader-content {
    margin: 50vh auto 0;
    transform: translateY(-50%);
    text-align: center;
    text-transform: uppercase;

    h2 {
      color: $white;
      font-weight: 500;
    }
  }
}


// Dashboard loader
//
#loader {
  z-index: 1000;
  display: none;
}

// Dashboard components
//
.dashboard-component-loader {
  display: block;
  margin-left: 0 !important;
  margin-top: 0 !important;
  color: transparent;

  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);

  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85px;
    height: 50px;
    translate: -50% -50%;
    background-repeat: no-repeat;
    background-image: linear-gradient($primary 50px, transparent 0),
    linear-gradient($primary 50px, transparent 0),
    linear-gradient($primary 50px, transparent 0),
    linear-gradient($primary 50px, transparent 0),
    linear-gradient($primary 50px, transparent 0),
    linear-gradient($primary 50px, transparent 0);

    image-rendering: pixelated;

    background-position: 0px center, 15px center, 30px center, 45px center, 60px center, 75px center, 90px center;
    animation: rikSpikeRoll 1.3s linear infinite alternate;
  }
}
.card .dashboard-component-loader {
  border-radius: 3px;
}
.modal .dashboard-component-loader {
  background: white;
  border-radius: 8px;
}
.spinner {
  &.spinner-small {
    width: 40px;
    height: 12px;
  }
  &.spinner-big {
    width: 106px;
    height: 55px;
  }
  &.spinner-white {
    background-image:
      linear-gradient(#FFF 100%, transparent 0),
      linear-gradient(#FFF 100%, transparent 0),
      linear-gradient(#FFF 100%, transparent 0),
      linear-gradient(#FFF 100%, transparent 0),
      linear-gradient(#FFF 100%, transparent 0),
      linear-gradient(#FFF 100%, transparent 0);
  }

  position: relative;
  display: inline-block;
  width: 85px;
  height: 50px;
  background-repeat: no-repeat;
  background-image:
    linear-gradient($primary 100%, transparent 0),
    linear-gradient($primary 100%, transparent 0),
    linear-gradient($primary 100%, transparent 0),
    linear-gradient($primary 100%, transparent 0),
    linear-gradient($primary 100%, transparent 0),
    linear-gradient($primary 100%, transparent 0);

  image-rendering: pixelated;

  background-position: 0% center, 15% center, 30% center, 45% center, 60% center, 75% center, 90% center;
  animation: rikSpikeRoll 1.3s linear infinite alternate;
}



// AJAX spinner
//
#ajax-spinner {
  margin: 15px 0 0 15px;
  padding: 13px;
  background: white url('../images/spinner.gif') no-repeat 50% 50%;
  font-size: 0;
  z-index: 123456;
  display: none;
}


// Print styles
//
@media print {
  #loader,
  .loader,
  .loader#loader {
    display: none !important;
  }
}

// Keyframes
//
@keyframes rikSpikeRoll {
  0% { background-size: 11.76% 6% }
  16% { background-size: 11.76% 100%, 11.76% 6%,   11.76% 6%,   11.76% 6%,   11.76% 6%,   11.76% 6% }
  33% { background-size: 11.76% 60%,  11.76% 100%, 11.76% 6%,   11.76% 6%,   11.76% 6%,   11.76% 6% }
  50% { background-size: 11.76% 20%,  11.76% 60%,  11.76% 100%, 11.76% 6%,   11.76% 6%,   11.76% 6% }
  66% { background-size: 11.76% 6%,   11.76% 20%,  11.76% 60%,  11.76% 100%, 11.76% 6%,   11.76% 6% }
  83% { background-size: 11.76% 6%,   11.76% 6%,   11.76% 20%,  11.76% 60%,  11.76% 100%, 11.76% 6% }
  100% { background-size: 11.76% 6%,  11.76% 6%,   11.76% 6%,   11.76% 20%,  11.76% 60%,  11.76% 100% }
}
