// VAR VALUE
//
// General
//
.var-value-card {
  .var-value-value {
    text-align: center;
    color: black;
    font-size: 60px;
  }
  .var-value-count {
    text-align: center;
    font-size: 12px;
  }
  .var-value-diff {
    font-size: 13px;
  }
}