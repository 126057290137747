// FILTER ITEM
//
// Indicates a specific item in the filter list
//
// 1. Filter Item
// 2. Item
// 3. Control
// 4. Filter Item Mini
//
// Imported into Filter.scss


// 1. FILTER ITEM
//
.FilterItem {
  &.closed > .subtree { display: none; }
  &.opened > .item { margin-bottom: 0.25rem; }
  &.opened > .item > .control > .icon-dropdown { transform: rotate(-90deg); }
}

// 2. ITEM
//
// You can place an icon to the left of all filter items (usually checkbox, radio etc.)
//
.FilterItem > .item {
  padding: 0.3125rem calc(0.5rem - 1px) 0.3125rem 0.3125rem;
  border-left: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(144, 144, 144, 0);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  // prevent text selection
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;

  &:focus-visible {
    outline: none;
  }

  // If an icon is embedded to the left in the filter, automatic label indentation is applied
  > .icon {
    margin-right: 0.2rem;
    transition: color 150ms;
  }

  > .item-label {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    min-width: 0;
    line-height: 16px;

    // The name of the filter
    > label {
      margin-bottom: 0;
      padding-right: 0.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.016rem;
      color: $text-dark;
      cursor: pointer;

      // Span shows data about used filters
      span { color: $text-gray; }
    }

    > .count {
      font-size: 10px;
      color: $text-gray;
      margin-left: 3px;
      animation-name: fade-in ;
      animation-duration: 200ms;
    }
  }
  .count.lowBase {
    color: $danger;
  }
}
.FilterItem {
  &:hover > .item,
  &:focus > .item { background-color: rgba(144, 144, 144, 0.15); }
  .no-items {
    padding-left: 0.75rem;
    font-size: 14px;
    opacity: 0.4;
    font-style: italic;
  }
}

// First Level Item
// .Filter > .FilterItem
//
// You can place an icon to the left of all items in the 1st level.
.tree > .FilterItem {
  padding: 0 0.5rem;

  &:first-child { padding-top: 0.25rem; }
  &:last-child { padding-bottom: 0.25rem; }
  &.opened { padding-bottom: 0.25rem; }

  > .item { padding: 0.625rem 0.5rem 0.625rem 0.824rem; }
  &.opened > .item { background-color: rgba(144, 144, 144, 0.15); }

  > .subtree {
    padding: 0 0 0.5rem 0;
    border-bottom: $b-dark;
  }
  &:last-child > .subtree:last-child { border-bottom: none; }
}

// 3. CONTROL
//
// You can place 2 icons in te control area (usually info-box and dropdown icon)
//
.FilterItem > .item > .control {
  display: flex;
  flex-direction: row;
  margin-left: 3px;

  > .icon {
    &::after {
      content: '';
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: width 200ms ease-in-out, height 200ms ease-in-out;
    }
    &:hover::after,
    &:focus::after {
      width: 90%;
      height: 90%;
    }
  }

  > .ico-inform::before { font-size: 1.875rem; }
  > .ico-dropdown {
    transform: rotate(-90deg);
    transition: transform 180ms;
  }
  > .ico-filter-remove::before {
    transform: scale(0.6);
  }
}
.FilterItem.opened > .item > .control {
  > .ico-dropdown {
    transform: rotate(0deg);
  }
}