.introjs-overlay
{
    position: absolute;
    z-index: 10000;

    -webkit-transition: all .3s ease-out;
         -o-transition: all .3s ease-out;
            transition: all .3s ease-out; 

    opacity: 0;
    background-color: #000;
}

.introjs-fixParent
{
    position: absolute !important;
    z-index: auto !important;

    -webkit-transform: none !important;
        -ms-transform: none !important;
         -o-transform: none !important;
            transform: none !important; 

    opacity: 1.0 !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th
{
    z-index: 10100 !important;
}

.introjs-disableInteraction
{
    position: absolute; 
    z-index: 10300 !important;
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th
{
    position: relative;
}

.introjs-helperLayer
{
    position: absolute;
    z-index: 10099;

    -webkit-transition: all .3s ease-out;
         -o-transition: all .3s ease-out;
            transition: all .3s ease-out; 

    border: 1px solid #777;
    border: 1px solid rgba(0, 0, 0, .5);
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, .4);
            box-shadow: 0 2px 15px rgba(0, 0, 0, .4);
}

.introjs-tooltipReferenceLayer
{
    position: absolute;
    z-index: 10101;

    -webkit-transition: all .3s ease-out;
         -o-transition: all .3s ease-out;
            transition: all .3s ease-out; 

    background-color: transparent;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after
{
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box; 

        -ms-box-sizing: content-box;
         -o-box-sizing: content-box;
}

.introjs-helperNumberLayer
{
    font-family: Arial, verdana, tahoma;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;

    position: absolute;
    z-index: 10400 !important;
    top: -16px;
    left: -16px;
    /* Old browsers */

    width: 20px;
    height: 20px;
    padding: 4px;

    text-align: center;

    color: white;
    border-radius: 50%;
    background: #62a8ea;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .4);
            box-shadow: 0 2px 5px rgba(0, 0, 0, .4); 
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.introjs-arrow
{
    position: absolute;

    display: none !important; 

    content: '';

    border: 5px solid white;
}

.introjs-arrow.top
{
    top: -10px;

    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
}

.introjs-arrow.top-right
{
    top: -10px;
    right: 10px;

    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
}

.introjs-arrow.top-middle
{
    top: -10px;
    left: 50%;

    margin-left: -5px;

    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: white;
    border-left-color: transparent;
}

.introjs-arrow.right
{
    top: 10px;
    right: -10px;

    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: white;
}

.introjs-arrow.right-bottom
{
    right: -10px;
    bottom: 10px;

    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: white;
}

.introjs-arrow.bottom
{
    bottom: -10px;

    border-top-color: white;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.introjs-arrow.left
{
    top: 10px;
    left: -10px;

    border-top-color: transparent;
    border-right-color: white;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.introjs-arrow.left-bottom
{
    bottom: 10px;
    left: -10px;

    border-top-color: transparent;
    border-right-color: white;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.introjs-tooltip
{
    font-size: 30px;

    position: absolute;

    min-width: 300px;
    max-width: 500px;
    padding: 10px;

    -webkit-transition: opacity .1s ease-out;
         -o-transition: opacity .1s ease-out;
            transition: opacity .1s ease-out; 

    color: #fff;
    background-color: transparent;
}
.introjs-tooltip .content
{
    font-size: 14px;

    display: block; 

    margin-top: 20px;
}

.introjs-tooltipbuttons
{
    margin-top: 10px;

    text-align: right;
    white-space: nowrap;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
.introjs-skipbutton
{
    font-size: 24px;

    position: fixed;
    right: 50px;
    bottom: 50px;

    width: 56px;
    height: 56px;
    margin: 0;
    margin-right: 5px;
    padding: 0;

    text-align: center;

    color: #fff !important;
    border: none;
    border-radius: 100%;
    background: #f96868 !important;
    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
            box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
    text-shadow: none;
}
.introjs-skipbutton i
{
    line-height: 56px;
}
.introjs-skipbutton:hover
{
    color: #fff;
    border: none;
    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
            box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
}
.introjs-skipbutton:active,
.introjs-skipbutton:focus
{
    background: #f96868;
}

.introjs-prevbutton,
.introjs-nextbutton
{
    font-size: 14px;
    line-height: 1.42857143;

    padding: 8px 18px;

    -webkit-transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear;
         -o-transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear;
            transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear;
    text-decoration: none; 

    color: #fff;
    border: 1px solid transparent;
    border-radius: 1000px;
    background-color: #62a8ea;

    -webkit-font-smoothing: subpixel-antialiased;
}
.introjs-prevbutton:hover,
.introjs-prevbutton:active,
.introjs-prevbutton:focus,
.introjs-nextbutton:hover,
.introjs-nextbutton:active,
.introjs-nextbutton:focus
{
    text-decoration: none; 

    color: #fff;
}
.introjs-prevbutton.introjs-disabled,
.introjs-nextbutton.introjs-disabled
{
    cursor: not-allowed; 

    color: #a3afb7 !important;
    border: 1px solid;
    background: transparent !important;
}

.introjs-prevbutton
{
    float: left;
}
.introjs-prevbutton i
{
    margin-right: 10px;
}

.introjs-nextbutton
{
    float: right;
}
.introjs-nextbutton i
{
    margin-left: 10px;
}

.introjs-disabled,
.introjs-disabled:hover,
.introjs-disabled:focus
{
    text-decoration: none;
}

.introjs-bullets
{
    text-align: center;
}

.introjs-bullets ul
{
    display: inline-block; 
    clear: both;

    margin: 15px auto 0;
    padding: 0;
}

.introjs-bullets ul li
{
    float: left;

    margin: 0 2px; 

    list-style: none;
}

.introjs-bullets ul li a
{
    display: block;

    width: 6px;
    height: 6px;

    text-decoration: none; 

    border-radius: 10px;
    background: #ccc;
}

.introjs-bullets ul li a:hover
{
    background: #999;
}

.introjs-bullets ul li a.active
{
    background: #999;
}

.introjs-progress
{
    overflow: hidden;

    height: 10px;
    margin: 10px 0 5px 0;

    border-radius: 4px;
    background-color: #ecf0f1;
}

.introjs-progressbar
{
    font-size: 10px;
    line-height: 10px;

    float: left;

    width: 0;
    height: 100%;

    text-align: center;

    background-color: #08c;
}

.introjsFloatingElement
{
    position: absolute;
    top: 50%; 
    left: 50%;

    width: 0;
    height: 0;
}

.introjs-fixedTooltip
{
    position: fixed;
}

.introjs-hint
{
    position: absolute;

    width: 20px;
    height: 15px;

    background: 0 0;
}

.introjs-hidehint
{
    display: none;
}

.introjs-fixedhint
{
    position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse
{
    border: 5px solid rgba(60, 60, 60, .57);
}

.introjs-hint-pulse
{
    position: absolute;
    z-index: 10;

    width: 10px;
    height: 10px;

    -webkit-transition: all .2s ease-out;
         -o-transition: all .2s ease-out;
            transition: all .2s ease-out; 

    border: 5px solid rgba(60, 60, 60, .27);
    border-radius: 30px;
    background-color: rgba(136, 136, 136, .24);
}

.introjs-hint-dot
{
    position: absolute;
    z-index: 1;
    top: -25px;
    left: -25px;

    width: 50px;
    height: 50px;

    -webkit-animation: introjspulse 3s ease-out;
         -o-animation: introjspulse 3s ease-out;
            animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
         -o-animation-iteration-count: infinite;
            animation-iteration-count: infinite; 

    opacity: 0;
    border: 10px solid rgba(146, 146, 146, .36);
    border-radius: 60px;
    background: 0 0;
}

@-webkit-keyframes introjspulse
{
    0%
    {
        -webkit-transform: scale(0); 

        opacity: 0;
    }
    25%
    {
        -webkit-transform: scale(0); 

        opacity: .1;
    }
    50%
    {
        -webkit-transform: scale(.1); 

        opacity: .3;
    }
    75%
    {
        -webkit-transform: scale(.5); 

        opacity: .5;
    }
    100%
    {
        -webkit-transform: scale(1); 

        opacity: 0;
    }
}
