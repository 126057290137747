@import "~nms-design-system-core/scss/variables/variables.scss";
.db-answers-table {
  .fixed-table-container {
    height: unset !important; // !important to overwrite inline css
    margin-bottom: 20px;
    padding-bottom: 0 !important; // !important to overwrite inline css
  }

  .fixed-table-header,
  .fixed-table-footer { display: none !important; } // !important to overwrite inline css

  .fixed-table-body table tr td {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  > .card-body .col-lg-12 {
    display: flex;
    justify-content: center;

    button { margin: 0 0.25rem; }
  }
}
