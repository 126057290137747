// Project list
//
// General
//
.select-project {
  margin: auto;
  max-width: 1200px;

  .col-xs-12 {
    padding-bottom: .9375rem;
    display: flex;
    flex-wrap: wrap;
  }

  p {
    text-align: center;
    color: $text-dark;

    &.welcome {
      font-weight: bold;
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }
    &.new-result {
      font-weight: lighter;
      font-size: 18px;
      margin-bottom: 4rem;
    }
  }
}


// Project card
//
.select-project .project-card {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $white;
  border-radius: 4px;
  box-shadow: $shadow-dark;

  &:hover,
  &:focus {
    box-shadow: 0 1px 6px 0 rgba(102, 181, 54, 0.6);
    transition: box-shadow 200ms;
  }

  .project-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media screen and (max-width: 1225px) {
    .project-image { height: 140px; }
  }
  @media screen and (max-width: 1150px) {
    .project-image { height: 130px; }
  }
  @media screen and (max-width: 1100px) {
    .project-image { height: 120px; }
  }
  @media screen and (max-width: 1050px) {
    .project-image { height: 110px; }
  }
  @media screen and (max-width: 991px) {
    .project-image { height: 192px; }
  }
  @media screen and (max-width: 810px) {
    .project-image { height: 181px; }
  }
  @media screen and (max-width: 767px) {
    .project-image { height: 144px; }
  }
  @media screen and (max-width: 589px) {
    .project-image { height: 118px; }
  }
  @media screen and (max-width: 408px) {
    .project-image { height: 180px; }
  }
  @media screen and (max-width: 372px) {
    .project-image { height: 150px; }
  }
  @media screen and (max-width: 318px) {
    .project-image { height: 130px; }
  }
  @media screen and (max-width: 282px) {
    .project-image { height: 100px; }
  }

  p {
    text-align: left;

    &.results-updated {
      font-weight: normal;
      font-size: 9px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.44px;
      color: $text-light;
      background: $success;
      position: relative;
      padding: 5px 20px;
      margin: -22px 0 0 0;
      opacity: 1;
    }
    &.project-title {
      font-weight: bold;
      font-size: 14px;
      padding: 20px 20px 5px 20px;
      margin: 0;
    }
    &.last-change {
      font-size: 11px;
      letter-spacing: 0.25px;
      color: $tertiary;
      margin: 5px 20px;
      padding: 0;
    }
    &:last-child { padding-bottom: 20px; }
  }

  a {
    color: $text-dark;
    text-decoration: none;
  }
  &:hover a,
  &:focus a {
    color: $success;
    text-decoration: none;
    transition: color 200ms;
  }
}


// Administrator project list
//
.project-table {
  table { background: $white; }
  .col-project_id {
    min-width: 70px;
    width: 70px;
  }
  .col-name { min-width: 250px; }
  .col-status { width: 115px; }
  .rotate-180 { transform: rotate(180deg); }

  // Status badges
  .badge-active { @extend .badge-success; }
  .badge-prepared {
    background: $accent;
    color: $white;
  }
  .badge-finished { @extend .badge-info; }
  .badge-deleted { @extend .badge-secondary; }
}