// FILTER
//
// Indicates a list of filters that are located in the sidebar in the content-wrap.
// Defines the basic appearance of the filter panel.
// Styles for specific elements are imported from related files.
//

.Filter {
  padding: 0.8rem 0 1.5rem 0;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  background: #FFFFFF;
  overflow-x: hidden;

  .subtree {
    padding-left: 1.25rem;
  }

  .icon {
    min-width: 1.875rem; // hack for IE
    width: 1.875rem;
    max-width: 1.875rem; // hack for IE
    height: 1.875rem;
    margin: -0.5rem 0;
  }

  .FilterItem {
    transition: opacity 400ms;
  }
  .FilterItem.removing {
    opacity: 0.4;
    pointer-events: none;
  }

  // Related files
  @import "Filter/FilterItem";
  @import "Filter/FilterItemGroup";
  @import "Filter/FilterMini";
  @import "Filter/FilterLoading";
  @import "Filter/FilterRange";
  @import "Filter/FilterItemCheckbox";
  @import "Filter/FilterItemFavourite";
  @import "Filter/FilterItemPeriod";
  @import "Filter/FilterItemSearch";
  @import "Filter/FilterItemTilesMatrix";
  @import "Filter/FilterItemTilesRow";
  @import "Filter/FilterItemTilesVariant";
  @import "Filter/FilterReset";
}


@import "Filter/FilterCalendar";
