// Stars chart
//
// General
//
.stars {
  max-width: 470px;
  height: 60px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  font-size: 2em;

  .gold { color: $warning; }
  .empty { color: $gray-400; }
}

@media print {
  .stars { margin: 0 !important; }
}

.stars-number {
  font-size: 0.9em;
  color: $gray-400;
  margin-top: -2px;

  strong {
    color: $warning;
    font-size: 1.2em;
  }
}