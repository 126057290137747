
.ImportGrid {
  td.col-id {
    color: $text-gray;
  }

  td.col-author {
    color: $text-gray;
  }

  td.col-contacts {
    position: relative;
  }

  th > small {
    display: block;
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }

  .col-id {
    width: 85px;
  }

  .col-created {
		width: 130px;
		max-width: 150px;
	}

	.col-ready_for_push,
	.col-stop,
	.col-error,
	.col-awaiting_pull,
	.col-finished {
		max-width: 80px;
	}

	.cell-progress-bar {
		background: $primary;
		position: absolute;
		height: 3px;
		left: 0;
		bottom: 0;
	}

	td.no-items-found {
		text-align: center;
		height: 280px;
	}

}

.header-row {
  .ico-chevron-down {
    transition: rotate 200ms;
  }

  [aria-expanded="true"] > .ico-chevron-down {
    rotate: -180deg;
  }
}

table.table tr.sub-row > td {
  height: unset;
  color: $text-gray;

  .icon-sm {
    margin-top: -15px;
    margin-bottom: -15px;
  }
}
