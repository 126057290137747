// VARIABLES
//

// Spacing
$card-p-n: -0.5rem !default;

// Dashboard Components Sizing
$dc-chart-min-height-lg: 400px;
$dc-chart-min-height-sm: 300px;
$dc-window-height-breakpoint: 600px;

// disable bootstrap printing styles
$enable-print-styles: false;

// fix small font size
$small-font-size: 80%;