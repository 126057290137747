// LAYOUT
//

// BODY
//
body {
  color: $text-dark;
  background: $bg-light-primary;
  overflow: scroll; // TODO: delete after layout behavior update
}
@media print {
  body {
    padding: 0 !important;
    color: #000000;
  }
}

// BODY WRAP
//
@media print {
  .body-wrap { padding-top: 0; }
}

// CONTENT WRAP
//
.content-wrap > .container-fluid {
  > .row > [class*='col-'] {
    margin-bottom: 15px;
  }
  > .row:last-child > [class*='col-']:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1200px) {
  .content-wrap > .container-fluid { transition: transform 0.25s; }
}
@include media-breakpoint-up(md) {
  .content-wrap > .nav { box-shadow: $shadow-light; }
}

@media print {
  .body-wrap .content-wrap {
    > .container-fluid > .row {
      page-break-inside: avoid;
      display: block;
      white-space: nowrap;

      > div {
        margin-right: -0.25em;
        white-space: normal;
        display: inline-block;
      }
    }

    > .nav {
      display: none;
    }
  }
  .body-wrap > .sidebar + .content-wrap {
    padding-left: 0 !important;
  }
}

// Sidebar na mobilu
.body-wrap > .sidebar { display: block; }
@media screen and (max-width: 991.98px) {
  .body-wrap > .sidebar { position: fixed; }
}

// FOOTER
//
@media print {
  .site-footer-phantomjs {
    margin: 3em 4.5em 0 4.5em;
    bottom: 3em;
    page-break-inside: avoid;
  }
}