@import "~nms-design-system-core/scss/variables/variables.scss";
// Section header
//
.db-section-header {
  &.card .card-header > div:first-child div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
