@import "~nms-design-system-core/scss/variables/variables.scss";
.UserOperatingPermissions {
  .dataset-group {
    background-color: $white;

    label { color: $text-dark; }

    small {
      text-overflow: ellipsis;
      text-wrap-mode: nowrap;
      max-width: 360px;
      overflow: hidden;
    }

    &.open {
      align-items: flex-start;
    }

    &.open,
    &:hover {
      background-color: #F2F6FA;

      label { color: $primary; }

      small {
        text-overflow: ellipsis;
        text-wrap-mode: wrap;
      }
    }
  }
  .controls {
    .btn {
      padding: 0;

      .icon {
        width: 24px;
        height: 24px;

        &::before {
          font-size: 34px;
        }
      }
    }
    .btn-flat-secondary:hover { background-color: rgba(144, 144, 144, 0.2); }
  }
}