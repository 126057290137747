// Component errors
//
.dashboard-component-errors {
  &:empty { display: none; }

  .error {
    padding: 0.75rem;
    margin: 0 auto;
    color: $danger;
    border: $b-danger;
    background-color: #{$danger}60;
    border-radius: .25rem;
  }
}
