// PRINT
//
// Page layout, cards wrapping

// General
//
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
  }

  @page {
    size: 1100px 1555.7142857143px; // === A3 paper
    margin: 40px 15px;
  }

  .chartNote {
    margin: 0;
  }

  body,
  .body-wrap,
  .body-wrap > .content-wrap,
  {
    display: block;
    background: transparent;
  }

  *,
  *::before,
  *::after {
    // Bootstrap specific; comment out `color` and `background`
    //color: $black !important; // Black prints faster
    text-shadow: none !important;
    //background: transparent !important;
    box-shadow: none !important;
  }

  a {
    &:not(.btn) {
      text-decoration: underline;
    }
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: $border-width solid $gray-500; // Bootstrap custom code; using `$border-width` instead of 1px
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  body {
    min-width: $print-body-min-width !important;
  }
  .container {
    min-width: $print-body-min-width !important;
  }

  .navbar {
    display: none;
  }
  .badge {
    border: $border-width solid $black;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table-bordered {
    th,
    td {
      border: 1px solid $gray-300 !important;
    }
  }

  [data-html2canvas-ignore] {
    display: none;
  }
}









// Printing class
body.printing {
  .print-rotated {
    display: block;
    position: relative;
    page-break-before: always;
    page-break-after: always;
    width: 260mm;
    height: 367mm;

    > div {
      position: absolute;
      top: 0; left: 0;
      transform: rotate(90deg) translate(0, -100%);
      transform-origin: top left;
      max-width: unset;
      height: 250mm;
      width: 367mm;

      > .card {
        overflow: hidden;
        height: 250mm;
        width: 100%;
      }
    }
  }
}
