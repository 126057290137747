.FilterItem.FilterItem-Range {
  > .subtree {
    padding-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    // rewrite design of noUi-slider
    .noUi-horizontal .noUi-handle {
      right: 0;
      top: -8.5px;
    }

    .noUi-handle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #FFFFFF;
      border: 1px solid rgba(25, 100, 170, 0.24);
      box-sizing: border-box;
      box-shadow: 0 0 4px rgba(25, 100, 170, 0.24);

      &::after,
      &::before {
        height: 12px;
        width: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border: 2px solid $primary-lightest;
        border-radius: 50%;
        background: $primary;
      }
      &::after {
        display: none;
      }
    }

    .noUi-target {
      border: none;
      background: #FFFFFF;
      box-shadow: none;
    }
    .noUi-horizontal {
      height: 3px;
    }
    .noUi-base {
      background-color: $gray-lightest;
    }
    .noUi-connect {
      background: $primary;
    }
    .noUi-origin, .noUi-connect {
      right: -5%;
    }

    > .slider-values {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      margin-left: -0.25rem;
      margin-right: -0.25rem;

      input {
        // Firefox
        -moz-appearance: textfield;

        // Chrome
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        max-width: 120px;
        &:last-child {
          text-align: right;
        }
      }
    }
  }

}
