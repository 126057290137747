// Segment controls
//
.segmented-controls {
  margin: 0 auto 1rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  overflow: auto;
  flex: 0 1 auto;

  .option {
    display: flex;
    align-items: stretch;

    input[type="radio"] { display: none; }

    label {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 6px 16px;
      color: $primary;
      font-size: 0.75rem;
      font-weight: 600;
      text-align: center;
      background: rgba(0, 99, 174, 0.2);
      cursor: pointer;

      &:hover { background: rgba(0, 99, 174, 0.25); }
    }

    > :checked + label {
      color: $text-light;
      background: $primary;

      &:hover {
        color: $text-light;
        background: $primary;
        cursor: default;
      }
    }

    &:first-child label {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      padding-left: 18px;
    }
    &:last-child label {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      padding-right: 18px;
    }
  }
}

@media print {
  .segmented-controls .option {
    label { display: none !important; }

    > :checked + label {
      display: block !important;
      color: $primary !important;
      background: #FFFFFF !important;
    }
  }
}


// Chart controls
//
// TODO: remove "periods-controls" if all chart card component were redesigned to new layout
//
.periods-controls,
.chart-controls {
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  letter-spacing: 0.4px;

  .col-form-label {
    padding-left: 12px;
    padding-right: 8px;
  }

  .btn-group {
    margin: 0 0.5rem 0.5rem 0;
    padding: 0;
    border: $b-transparent;
    border-radius: 1rem;

    &:last-child { margin-right: 0; }

    .btn {
      &.btn-xs  {
        margin: 0 0.5px;
        padding: 2px 8px;
        border-radius: 1rem;
        font-size: 0.875rem;
        transition: all 100ms ease-out;

        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
      }
    }
    .btn-flat-secondary:hover {
      background: transparent;
      border: $b-primary;
      color: $text-dark;
    }
    .btn-flat-secondary.active {
      background-color: transparent;
      border: $b-primary;
      color: $primary;
      box-shadow: none;
    }
  }

  select {
    min-width: 60px;
    height: unset; // reset default select tag size
    text-transform: none;
    padding: 0.188rem 2rem 0.188rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    text-align: left;
    transition: all 100ms ease-out;

    &:last-child { margin-right: 0; }

    &.btn {
      flex: 1 1 0;
    }

    option { font-size: 0.875rem; }
  }

  .nav {
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: solid 1px $gray-200;

    .nav-link { cursor: pointer; }
    .nav-link input[type="radio"] { display: none; }
  }
}

// Exception for PNG export - show selected option
.html2canvas .chart-controls select {
  line-height: 26px;
  padding-top: 0;
  padding-bottom: 0;
}
