@import "~nms-design-system-core/scss/variables/variables.scss";
.bootstrap-select {
  .btn {
    width: 100%;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    background: transparent;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.5;
    color: $text-dark;
  }

  .dropdown-item {
    font-size: 12px;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
