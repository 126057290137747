// Styles for main login screen
//
// General
//
body.sign-in {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: $primary;

  .container-fluid {
    width: 100%;
    min-height: 95vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $white;
  }
  .theme-illustration {
    width: 100%;
    height: 5vh;
    background: linear-gradient(0deg, rgba(25, 100, 170, 0.35) 0%, rgba(25, 100, 170, 0.35) 100%), linear-gradient(231deg, #1964AA 6.27%, #009FE3 128.79%);
  }

  .app-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    img {
      max-width: 80%;
      min-height: 25px;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  h1 {
    margin: 0;
    display: none;
    font-family: 'Barlow Condensed', Arial, sans-serif;
    font-size: 6vh;
    font-style: normal;
    font-weight: 300;
    text-align: center;
    line-height: 120%;
    color: $text-dark;
  }

  form {
    width: 100%;
    max-width: 320px;
    margin: 1vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-group {
      margin-bottom: 3vh;

      label { margin: 0; }

      .form-control {
        margin: 0;
        border-radius: 3px;
      }
    }

    button[type="submit"] { margin: 3vh auto; }
    a.btn { margin: auto; }
  }

  footer {
    margin: 3vh auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    line-height: 100%;
    color: $text-gray;

    img { margin-left: 10px; }
  }
}
@include media-breakpoint-up(sm) {
  body.sign-in {
    .container-fluid { min-height: 90vh; }
    .theme-illustration { height: 10vh; }
    form { margin: 6vh auto; }
    .app-logo { align-items: end; }
  }
}
@include media-breakpoint-up(md) {
  body.sign-in {
    flex-direction: row;
    flex-wrap: nowrap;

    .container-fluid {
      width: 90vw;
      min-height: 100vh;
      order: 1;
    }
    .theme-illustration {
      width: 10vw;
      height: 100vh;
      order: 2;
    }

    .form { flex: 3; }

    h1 {
      display: block;
      font-size: 40px;
    }

    form {
      margin: 20px auto;

      .form-group { margin-bottom: 20px; }

      button[type="submit"] { margin: 20px auto; }
      a.btn { margin: auto; }
    }

    .languages {
      position: absolute;
      bottom: 3vh;
      right: 5vh;
    }
  }
}
