// GENERAL
//

@media (max-width: 767px) {
  .disable-scrolling {
    overflow: hidden;
    height: 100%;
  }
}

* { box-sizing: border-box; }

.unknown-component {
  background: #E4E4E4;
  font-weight: bold;
  color: #989898;
}

.text-muted {
  color: $text-dark;
  opacity: 0.5;
}

.mw-unset { max-width: unset; }
.mh-unset { max-height: unset; }

.cursor-pointer { cursor: pointer; }

::-webkit-file-upload-button {
  border: $b-primary;
  background: transparent;
  color: $primary;
  cursor: pointer;
}

a[href^="#error:"] {
  background: $danger;
  color: $text-light;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.2;
}

.fs-1 { font-size: calc(1.375rem + 1.5vw) !important; }
.fs-2 { font-size: calc(1.325rem + .9vw) !important; }
.fs-3 { font-size: calc(1.3rem + .6vw) !important; }
.fs-4 { font-size: calc(1.275rem + .3vw) !important; }
.fs-5 { font-size: 1.25rem !important; }
.fs-6 { font-size: 1rem !important; }
.fs-7 { font-size: 0.875rem !important; }
.fs-8 { font-size: 0.75rem !important; }
.fs-9 { font-size: 0.625rem !important; }
.fs-10 { font-size: 0.5rem !important; }

.tooltip-inner { text-align: left; }
.not-allowed { cursor: not-allowed !important; }


// User settings form
//
section {
  margin-top: 15px;

  &.new-password {
    display: flex;
    flex-direction: column;

    label { margin: 0; }
  }
}


// List group
//
ul.flashes.list-group.list-group-gap {
  margin: 114px 20% -88px 20%;
  text-align: center;
  position: fixed;
  top: -115px;
  width: 60%;
  z-index: 9999;
}

.list-group-item {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: none;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 100%;
  color: $text-dark;

  &:first-child,
  &:last-child { border-radius: 3px;}
}
.list-group-item-error {
  color: #FF5B4A;
  border-color: #FF5B4A;
  background-color: rgba(255, 91, 74, 0.33);
}
.list-group-item-warning {
  color: #E67E22;
  border-color: #E67E22;
  background-color: rgba(230, 126, 34, 0.33);
}
.list-group-item-info {
  color: #009FE3;
  border-color: #009FE3;
  background-color: rgba(0, 159, 227, 0.33);
}


// Search field in filter
//
.site-menu-option-filter {
  text-align: center;
  padding: 10px 10px;
}


// Detail dotazníku - info o odeslání redalertu
//
span.red { color: $danger; }



[data-component-open-close-toggle].target-closed {
  // jak bude vypadat tlačítko filtru pokud je zakliklé
}

.development-message {
  color: white;
  border: 1px solid indianred;
  background: darkred;
  width: auto;
  text-align: center;
  font-weight: 400;
  font-size: 70%;
  padding: 1px 10px;
  z-index: 10000000;
  position: fixed;
  top: 1px;
  right: 21px;

  a {
    color: wheat;
    text-decoration: none;
  }
}

.icon {
  color: #909090;

  &.icon-sm {
    width: 30px;
    height: 30px;
  }
  &.icon-xs {
    width: 24px;
    height: 24px;
  }
}

.html2canvas .container-fluid { background-color: #EBF4FF; }

.db-client-specific-csob-positive-feedback-email {
  .form-control:disabled,
  .form-control[readonly] { background-color: $white; }
}

.select2 textarea { min-height: unset; }

@media print {
  .print-page-break-before { break-before: page; }
}

.badge-accent { background-color: $accent;}