@import "~nms-design-system-core/scss/variables/variables.scss";
@import "../../../../../style/variables";

.db-active-filters {
  @media screen {
    &:not(.expanded) {

      .card-header {
        border-bottom: none;
        padding-bottom: 1rem;
        .ico-chevron-down {
          transform: rotate(-180deg);
        }
      }

      .card-body {
        display: none;
      }
    }
  }

  .active-filter-description {
    display: none;
  }
  .active-filters-content {
    .active-filter {
      display: inline-flex;
      background-color: #89AFE2;
      color: white;
      border-radius: 12px;
      margin-right: 6px;
      line-height: 24px;
      font-size: 10px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: 500;

      &.reset-all {
        background-color: $danger;
      }

      &.active-filter-dummy {
        background-color: $gray-400;
        &:nth-child(1) .active-filter-inner {
           width: 130px;
        }
        &:nth-child(2) .active-filter-inner {
          width: 100px;
        }
        &:nth-child(3) {
          opacity: 0.7;
          .active-filter-inner {
              width: 140px;
          }
        }
        &:nth-child(4) {
          opacity: 0.25;
          .active-filter-inner {
            width: 120px;
          }
        }
      }

      .active-filter-inner {
        display: flex;
        padding-left: 6px;
        padding-right: 6px;
        cursor: default;

        .active-filter-label {
          white-space: nowrap;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }

        .active-filter-count {
          margin-left: 4px;
          display: inline-block;
        }
      }

      .action-reset {
        margin-right: -1px;
      }

      .icon {
        color: #F3F3F3;
        height: 24px;
        width: 24px;

        &:hover {
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}

.html2canvas .active-filters-card .active-filters-content .active-filter .active-filter-inner {
  display: inline-block;
  .active-filter-label {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: clip;
    display: inline;
  }
}

