@import "~nms-design-system-core/scss/variables/variables.scss";
.SwitchProject {
  position: relative;
  font-size: 0.75rem;
  padding: 1rem 0.5rem 0 0.5rem;

  > a { padding-left: 0.5rem; }

  select {
    max-width: 100%;
    width: 100%;
    min-width: 150px;
  }

  .select2,
  .select2-container--default .select2-selection--single { border: none; }
  .select2-selection--single {
    padding: 0 20px 0 8px;

    &:focus-visible { outline: none; }
  }
  .select2-selection { background: none !important; }
  .select2-container--default .select2-selection--single .select2-selection__rendered { padding: 0;}
  .select2-container--default .select2-selection--single .select2-selection__arrow { right: 13px; }
}

.SwitchProject-dropdown {
  &.select2-dropdown {
    font-size: 0.75rem;

    &.select2-dropdown--below { border-top: 1px solid #aaa; }
  }
}