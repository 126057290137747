.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  display: none; // it is enabled from js

  &:after, &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }

  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;

    &:after {
      content: "✖";
    }
  }

  .ol-popup-content {
    .feature {
      &:not(:first-child) {
        margin-top: 0.5rem;
      }

      h3 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .data {
        font-size: 12px;
        label {
          margin-bottom: 0;
        }
      }
    }
  }

}

