// NPS chart
//
// General
//
.nps-men-container { text-align: center; }

.nps-men {
  max-width: 470px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 0.8em;
  justify-content: space-around;

  span {
    display: inline-block;
    margin-top: 2px;
  }

  > div {
    color: $text-gray;

    > img {
      padding: 6px 10px;
      border-top: solid 5px #FFFFFF;
      border-bottom: solid 5px #FFFFFF;
    }
  }

  .active {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.05em;

    span { margin-top: 5px; }
  }
}
@media screen and (max-width: 768px) {
  .nps-men > div > img { padding: 6px 7px; }
}

@media print {
  .nps-men {
    margin: 0 !important;

    div.active span::after {
      content: "/10";
      font-size: 10px;
    }
  }
}


// Colors
//
.nps-men {
  .red.active {
    color: $danger;

    img { border-color: $danger; }
  }
  .yellow.active {
    color: $warning;

    img { border-color: $warning; }
  }
  .green.active {
    color: $success;

    img { border-color: $success; }
  }
}