@import "~nms-design-system-core/scss/variables/variables.scss";
.table.has-excel-filter {
  thead th {
    position: relative;
  }
  .excelFilter {
    display: inline;
    font-weight: normal;

    &.open {
      .excelFilter-controls {
        height: 30px;
        background-color: $white;
        border-radius: 8px 8px 0 0;

        .icon { color: $text-dark; }
      }
      .excelFilter-items {
        width: 200px;
        position: absolute;
        top: calc(50% + 15px);
        right: unset;
        left: 0;
        background-color: $white;
        border: $b-transparent;
        border-top-color: transparent;
        border-radius: 8px;
        box-shadow: $shadow-dark;
        order: 1;
        z-index: 1;
      }
    }

    &:not(.active) .ico-filter-remove { display: none; }

    .excelFilter-controls {
      white-space: nowrap;

      .icon {
        height: 30px;
        width: 30px;
        color: $text-light;
        cursor: pointer;

        &::before { font-size: 30px; }

        &.ico-filter-remove { float: left; }
      }
    }

    .excelFilter-items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .item {
      width: 100%;
      padding: 5px;
      color: $text-dark;

      &:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.selected {
        background-color: $primary-dark;
        color: white;

        &:hover,
        &:focus { background-color: $primary-dark; }
      }

      &:hover,
      &:focus {
        cursor: pointer;
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}