.map-container {
  position: relative;

  .ol-map {
    height: 500px;
    @media (screen and max-height: $dc-window-height-breakpoint) {
      height: 300px;
    }
    width: 100%;
  }

  .ol-zoom-hint {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 100%;
    height: 100%;
    pointer-events: none;
    color: white;
    background: rgba(0,0,0,0.3);
    font-size: 40px;
    text-align: center;
    font-weight: 700;

    transition: opacity 150ms;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }

  @import "Map/Popup";
}

