// MODAL CALENDAR
//

// FLATPICKR
//
.flatpickr-calendar.inline {
  top: 0;
  border-radius: 0;
  box-shadow: unset;
}

// Flatpickr Months
.flatpickr-months {
  margin-bottom: 1rem;

  .flatpickr-prev-month,
  .flatpickr-next-month {
    min-width: 2.5rem;
    width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: $text-gray;

    &::before {
      font-family: nmsicons !important;
      font-style: normal;
      font-weight: normal !important;
      vertical-align: top;
      font-size: 40px;
      line-height: 100%;
      text-rendering: geometricPrecision;
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: width 200ms ease-in-out, height 200ms ease-in-out;
    }

    &:hover::after,
    &:focus::after {
      width: 90%;
      height: 90%;
    }

    > svg { display: none; }
  }
  .flatpickr-prev-month { @extend .ico-chevron-left; }
  .flatpickr-next-month { @extend .ico-chevron-right; }

  .flatpickr-month { height: unset; }
  .flatpickr-current-month {
    width: 100%;
    height: unset;
    padding: 0;
    left: 0;
    display: flex;
    flex: 1;
    font-size: 0.875rem;

    .flatpickr-monthDropdown-months,
    .numInputWrapper .cur-year {
      padding: 0.75rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.047rem;
      text-transform: uppercase;

      &:hover,
      &:focus {
        border-radius: 3px;
        background: rgba(144, 144, 144, 0.1);
      }
    }

    .numInputWrapper {
      width: 75px;

      span {
        width: 20px;
        height: 20px;
        padding: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        opacity: unset;
        line-height: 100%;
        color: $text-gray;

        &:hover,
        &:focus {
          background: transparent;
        }

        &::before {
          font-family: nmsicons !important;
          font-style: normal;
          font-weight: normal !important;
          vertical-align: top;
          font-size: 40px;
          line-height: 100%;
          text-rendering: geometricPrecision;
        }
        &::after {
          content: '';
          width: 0;
          height: 0;
          background: rgba(0, 0, 0, 0.1);
          border: unset;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: width 200ms ease-in-out, height 200ms ease-in-out;
        }

        &:hover::after,
        &:focus::after {
          width: 80%;
          height: 80%;
        }

        &.arrowUp {
          top: 5px;

          @extend .ico-dropup;
        }
        &.arrowDown {
          top: calc(50% - 5px);

          @extend .ico-dropdown;
        }
      }
    }

    .flatpickr-monthDropdown-months + .numInputWrapper { margin-left: 0.5rem; }
  }
}

// Flatpickr Weekdays
.flatpickr-weekdays {
  height: auto;
  margin-bottom: 0.5rem;

  span.flatpickr-weekday {
    font-size: 0.75rem;
    font-weight: normal;
    letter-spacing: 0.025rem;
    color: $text-dark;
  }
}

// Flatpickr Days
.flatpickr-days {
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay { color: rgba(58, 58, 58, 0.15); }
}


// Table modification
.datagrid .flatpickr-calendar {
  &.static { top: calc(100% + 20px); }

  .flatpickr-prev-month.flatpickr-disabled + .flatpickr-month { margin-left: 40px; }
  .flatpickr-month {
    max-width: calc(100% - 80px);
    height: 40px;
  }
  .flatpickr-current-month { justify-content: center; }
  .flatpickr-current-month select {
    min-width: auto;
    flex: 1;
  }
  .flatpickr-current-month input[type="number"] {
    width: auto;

    &:hover,
    &:focus { border-width: 0; }
  }
  span.flatpickr-weekday { line-height: normal; }
}