// Style highchart (svg)

// Example of default styles
// @import "~highcharts/css/highcharts";

// Tooltip
.highcharts-tooltip-box {
  fill-opacity: 1;
  stroke: white;
  filter: drop-shadow( 3px 3px 8px rgba(0, 0, 0, .3));
  fill: white;
}

.highcharts-container svg text {
  font-family: "Roboto", sans-serif;
}