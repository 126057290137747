// INTERVAL CONTROL
//
.site-menu .interval-control {
  position: absolute;
  left: 0;
  text-align: center;
  width: 15px;

  &::after {
    content: '️↕';
    color: $tertiary-light;
    opacity: 0.1;
    transition: opacity 0.3s;
  }
}
.selecting-mode-interval .site-menu .interval-control {
  &::after {
    content: '️·';
    opacity: 0.8;
  }
  &.init::after {
    content: '️↕';
    opacity: 1;
  }
  &.start::after {
    content: '️↓';
    opacity: 1;
  }
  &.interval::after {
    content: '•';
    opacity: 1;
  }
  &.end::after {
    content: '️↑';
    opacity: 1;
  }
}
.site-menu a:hover > .interval-control::after,
.site-menu a > .interval-control:hover::after {
  opacity: 1;
}